import React, { useContext } from "react";
import { Link } from "gatsby";
import Case from "case";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";

function CartItemSummary(props) {
  const cartContext = useContext(CartContext);

  function removeItemFromCart() {
    cartContext.removeItemFromCart(props.id);
  }

  return (
    <div
      key={props.id}
      className="flex flex-wrap justify-between my-4 py-2 items-top"
    >
      <div className="w-1/3 md:w-1/5 text-center">
        <Link to={props.url}>
          <img
            src={props.image}
            alt={props.title}
            style={{ maxWidth: "110px" }}
          />
        </Link>
      </div>
      <div className="w-2/3 pl-4 md:w-4/5">
        <div className="flex flex-wrap pr-6 items-center">
          <h5 className="w-full md:w-2/3 text-base font-medium mb-2 pt-1 flex-grow">
            <Link className="no-underline text-grey-darkest" to={props.url}>
              {props.title}
            </Link>
          </h5>
          <div className="w-1/2 md:w-1/6">
            <Formik
              initialValues={{ quantity: props.quantity }}
              onSubmit={(values, { setSubmitting }) => {
                cartContext.setItemQuantity(props.id, values.quantity);
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                quantity: Yup.number().required("Required"),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <input
                      type="number"
                      name="quantity"
                      value={values.quantity}
                      onChange={(e) => {
                        handleChange(e);
                        setTimeout(() => {
                          handleSubmit();
                        });
                      }}
                      onBlur={handleBlur}
                      min="1"
                      className={
                        errors.quantity && touched.quantity
                          ? "py-2 px-4 w-full border border-red-dark"
                          : "py-2 px-4 w-full border border-grey-dark"
                      }
                    />
                    {errors.quantity && touched.quantity && (
                      <div className="text-red-dark">{errors.quantity}</div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        {props.attributes.map((item, index) => {
          return (
            <p className="text-xs font-light" key={index}>
              {Case.sentence(item.variation_attribute_name)}:{" "}
              {Case.sentence(item.variation_attribute_value)}
            </p>
          );
        })}
        {props.style && (
          <p className="text-grey-dark text-xs font-light">
            Style: {props.style}
          </p>
        )}
        {props.size && (
          <p className="text-grey-dark text-xs font-light">
            Size: {props.size}
          </p>
        )}
        <p className="pt-4 text-sm">
          <button className="underline" onClick={removeItemFromCart}>
            Delete
          </button>
        </p>
      </div>
    </div>
  );
}

CartItemSummary.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  attributes: PropTypes.array,
};

export default CartItemSummary;
